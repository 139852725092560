<template>
  <div>
    <div class="form-group">
      <label for="search">Search</label>
      <input id="search" class="form-control" type="text" v-model="searchTerm" @input="find()">
    </div>
    <ul class="list-group">
      <li class="list-group-item" v-if="guardians.length > 0"><h5>Guardians</h5></li>
      <li class="list-group-item d-flex align-items-center justify-content-between" v-for="guardian in guardians" :key="guardian.id">
        <div>{{ guardian.firstName }} {{ guardian.lastName }} ({{ guardian.emailAddress }})</div>
        <button class="btn btn-primary" @click="impersonateGuardian(guardian.emailAddress)">Access Account</button>
      </li>
      <li  class="list-group-item" v-if="students.length > 0"><h5>Students</h5></li>
      <li class="list-group-item d-flex align-items-center justify-content-between" v-for="student in students" :key="student.id">
        <div>{{ student.firstName }} {{ student.lastName }}</div>
        <button class="btn btn-primary" @click="impersonateAccount(student.accountId)">Access Account</button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Guardian from '@/types/guardian'
import Student from '@/types/student'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      waiting: undefined as undefined | number,
      searchTerm: '',
      students: [] as Student[],
      guardians: [] as Guardian[]
    }
  },
  methods: {
    find () {
      if (this.waiting) {
        window.clearTimeout(this.waiting)
      }
      if (this.searchTerm.length < 1) {
        return
      }
      this.waiting = window.setTimeout(async () => {
        const response = await this.$api.search(this.searchTerm)
        if (response.students || response.guardians) {
          this.students = response.students || []
          this.guardians = response.guardians || []
        }
      }, 1000)
    },
    impersonateGuardian (emailAddress: string) {
      this.$api.impersonateStart({ emailAddress: emailAddress })
    },
    impersonateAccount (accountId: number) {
      this.$api.impersonateStart({ accountId: accountId })
    }
  }
})
</script>
