<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <admin-nav />
      <div class="col-lg-10 col-sm-12">
        <div class="container">
          <h4>Admin Home</h4>
          <guardian-search />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AdminNav from '@/components/AdminNav.vue'
import GuardianSearch from '@/components/GuardianSearch.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { GuardianSearch, AdminNav }
})
</script>
