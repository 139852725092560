
import Guardian from '@/types/guardian'
import Student from '@/types/student'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      waiting: undefined as undefined | number,
      searchTerm: '',
      students: [] as Student[],
      guardians: [] as Guardian[]
    }
  },
  methods: {
    find () {
      if (this.waiting) {
        window.clearTimeout(this.waiting)
      }
      if (this.searchTerm.length < 1) {
        return
      }
      this.waiting = window.setTimeout(async () => {
        const response = await this.$api.search(this.searchTerm)
        if (response.students || response.guardians) {
          this.students = response.students || []
          this.guardians = response.guardians || []
        }
      }, 1000)
    },
    impersonateGuardian (emailAddress: string) {
      this.$api.impersonateStart({ emailAddress: emailAddress })
    },
    impersonateAccount (accountId: number) {
      this.$api.impersonateStart({ accountId: accountId })
    }
  }
})
